.toggle-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.toggle-slot {
    position: relative;
    height: 23px; /* Ваше нове значення */
    width: 55px; /* Ваше нове значення */
    border: 3px solid #e4e7ec; /* Нове значення */
    border-radius: 23px; /* Половина висоти */
    background-color: white;
    /* box-shadow: 0px 5px 12.5px #e4e7ec; Половина зазначених відстаней */
    transition: background-color 250ms;
}

.toggle-checkbox:checked ~ .toggle-slot {
    background-color: #374151;
}

.toggle-button {
    transform: translate(32px, 1px); /* Нове значення */
    position: absolute;
    height: 15px; /* Нове значення */
    width: 15px; /* Нове значення */
    border-radius: 50%;
    background-color: #ffeccf;
    box-shadow: inset 0px 0px 0px 2.5px #fc0; /* Половина відповідних значень */
    transition:
        background-color 250ms,
        border-color 250ms,
        transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .toggle-button {
    background-color: #485367;
    box-shadow: inset 0px 0px 0px 2.5px white; /* Половина відповідних значень */
    transform: translate(2px, 1px); /* Нове значення */
}

.sun-icon {
    position: absolute;
    height: 15px; /* Нове значення */
    width: 15px; /* Нове значення */
    color: #fc0;
}

.sun-icon-wrapper {
    position: absolute;
    height: 15px; /* Нове значення */
    width: 15px; /* Нове значення */
    opacity: 1;
    transform: translate(2px, 1px) rotate(15deg); /* Нове значення */
    transform-origin: 50% 50%;
    transition:
        opacity 150ms,
        transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
    opacity: 0;
    transform: translate(32px, 1px) rotate(0deg); /* Нове значення */
}

.moon-icon {
    position: absolute;
    height: 15px; /* Нове значення */
    width: 15px; /* Нове значення */
    color: white;
}

.moon-icon-wrapper {
    position: absolute;
    height: 15px; /* Нове значення */
    width: 15px; /* Нове значення */
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg); /* Нове значення */
    transform-origin: 50% 50%;
    transition:
        opacity 150ms,
        transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
}

.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
    opacity: 1;
    transform: translate(32px, 1px) rotate(-15deg); /* Нове значення */
}

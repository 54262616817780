.formInputs {
    /* background: linear-gradient(
    90deg,
    rgba(156, 156, 156, 0.81) 20%,
    #414141 100%
  ) !important; */

    color: #ffffff !important;
    margin-bottom: 50px;
    width: 100%;
    height: 96px !important;
    font-size: 30px !important;
    box-shadow: -3px 4px 7.2px 1px rgba(0, 0, 0, 0.38);
    border: none;
    font-family: Arimo;
}

.inputOneColor {
    background: linear-gradient(
        90deg,
        rgba(244, 244, 244, 0.25) 100%,
        rgba(102, 102, 102, 0.25) 0%
    ) !important;
}

.inputTwoColor {
    background: linear-gradient(90deg, #191919 0%, #3d3d3d 100%) !important;
}

.feedBack .inputOneColor {
    background: linear-gradient(
        90deg,
        rgba(156, 156, 156, 0.41) 0%,
        #414141 100%
    ) !important;
}

.feedBack .inputTwoColor {
    background: linear-gradient(
        90deg,
        rgba(156, 156, 156, 0.41) 0%,
        #414141 100%
    ) !important;
}

.formInputs::placeholder {
    color: #ffffff;
    font-size: 30px;
    font-family: Arimo;
}

.formInputs:focus {
    border: none;
}

.button-submit {
    font-family: Arimo;
    background: linear-gradient(90deg, #765e00 100%, #e3b600 0%) !important;

    box-shadow: 0px 0px 65.593px 0px rgba(185, 148, 0, 0.6) !important;
    color: #ffffff !important;
    font-size: 75.663px !important;
    height: 115px !important;
    transition: all 0.5s;
}

@media (hover: hover) {
    .button-submit:hover {
        background: linear-gradient(90deg, #191919 0%, #3c3c3c 100%) !important;
        box-shadow: 0px 3.546px 28.01px 0px rgba(0, 0, 0, 0.87) inset !important;
        color: #ffd000 !important;
        transition: all 0.5s;
    }
}

@media screen and (max-width: 800px) {
    .formInputs {
        padding: 23px 27px;
    }

    .formInputs::placeholder {
        color: #fff;

        font-family: Arimo;
        font-size: 25.311px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .button-submit {
        max-width: 553.291px;
        height: 76.944px;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media screen and (max-width: 600px) {
    .formInputs {
        /* padding: 14px 16px; */
        width: 325.465px !important;
        height: 45.261px !important;
        margin: 26px auto;
        font-size: 14.889px !important;
    }

    .formInputs::placeholder {
        color: #fff;

        font-family: Arimo;
        font-size: 14.889px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .button-submit {
        width: 325.465px !important;
        height: 45.261px !important;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;

        font-family: Arimo;
        font-size: 29.777px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

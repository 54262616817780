.why-button-container {
    box-shadow: 0px 0px 26.316px 0px #ffd000;
    max-width: 450px;
    width: 100%;
    height: 109.868px;
}

.whywe-button {
    max-width: 450px;
    width: 100%;
    height: 109.868px;
    font-size: 36px;
    font-family: Arimo;
}

.benefits-list {
    max-width: 1560px;
    width: 100%;
    /* justify-content: space-between; */
    gap: 20px;
}

.why-we-bottom-proposition {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.benefits-item {
    max-width: 500px;
    width: 100%;
    height: 500px;
    position: relative;
    font-family: Arimo;
}

.benefits-item-front {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    padding: 22px;
    position: absolute;
    top: 0;
    left: 0;
    /* opacity: 1; */
    font-family: Arimo;

    background: linear-gradient(166deg, rgba(255, 204, 0, 0.65) 0%, #fd5 100%);
    box-shadow:
        0px 3.5px 3.5px 0px rgba(0, 0, 0, 0.25),
        4.375px 4.375px 8.75px 0px rgba(0, 0, 0, 0.55);
}

.benefits-item-front-container {
    width: 100%;
    height: 100%;
    border: 1.75px solid #1f1f1f;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Arimo;
}

.benefits-item-front-span {
    font-family: Arimo;
    font-size: 26.25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(92deg, #1f1f1f 0%, #131313 99.23%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.benefits-item-back {
    /* visibility: hidden; */
    width: 100%;
    height: 100%;
    background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
    transition: all 0.5s;
    /* opacity: 0; */
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 22px;
    /* margin: 22px; */
}

.why-we-bottom-title {
    color: #ececec;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.benefits-item-back-container {
    width: 100%;
    height: 100%;
    border: 1.802px solid #e0b50c;
    padding: 20px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
}

.benefits-item:hover .benefits-item-front {
    opacity: 0;
    transition: all 0.5s;
}

.benefits-item:hover .benefits-item-back {
    opacity: 1;
    transition: all 0.5s;

    /* background: #111110;
  box-shadow: 0px 0px 8.223px 0px #000 inset; */
}

.benefits-item-back-title {
    text-shadow: 4.505px 4.505px 9.009px rgba(0, 0, 0, 0.55);
    font-family: Arimo;
    font-size: 76.577px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;
}

.benefits-item-back-text {
    text-shadow: 4.505px 4.505px 9.009px rgba(0, 0, 0, 0.55);
    font-family: Arimo;
    font-size: 36.036px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1530px) {
    .benefits-item {
        max-width: 381px;
        max-height: 381px;
    }

    .benefits-list {
        max-width: 1200px;
    }

    .benefits-item-back-title {
        font-size: 50px;
    }

    .benefits-item-back-text {
        font-size: 26px;
    }

    .why-we-bottom-container {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1400px) {
    .why-we-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 45.769px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .why-we-description {
        color: #fff;

        font-family: Arimo;
        font-size: 22.885px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .why-we-bottom-container {
        left: 0px;
    }

    .why-we-bottom-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .why-we-bottom-proposition {
        color: #fff;

        font-family: Arimo;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .why-button-container {
        width: 450px;
        height: 109.868px;
    }

    .whywe-button {
        width: 450px;
        height: 109.868px;
    }
}

@media screen and (max-width: 1250px) {
    .why-we-bottom-container {
        padding-top: 100px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        width: auto;
        align-items: flex-end;
        padding-right: 56px;
    }

    .why-we-bottom-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 800px;
        text-align: end;
    }

    .why-we-bottom-proposition {
        color: #fff;
        text-align: end;

        font-family: Arimo;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 600px;
    }

    .why-button-container {
        width: 450px;
        height: 109.868px;
    }

    .whywe-button {
        width: 450px;
        height: 109.868px;
    }

    .benefits-item {
        width: 248.978px;
        height: 249.348px;
    }

    .benefits-list {
        max-width: 800px;
    }

    .benefits-item-front img {
        width: 44.861px;
        height: 44.927px;
        margin-bottom: 10px;
    }

    .benefits-item-front-span {
        font-size: 10.338px;
    }

    .benefits-item-back-container {
        padding: 10px;
    }

    .benefits-item-back-title {
        font-size: 30px;
        margin-bottom: 15px;
    }

    .benefits-item-back-text {
        font-size: 15px;
    }
}

/* @media screen and (max-width: 1150px) {

  .benefits-item {
    width: 381px;
    height: 381px;
  }
} */

@media screen and (max-width: 850px) {
    .why-we-bottom-container {
        align-items: flex-end;
        padding-right: 56px;
    }

    .why-we-bottom-title {
        font-size: 50.407px;
        text-align: end;
        max-width: 700px;
    }

    .why-we-bottom-proposition {
        text-align: end;
        max-width: 600px;
        font-size: 23.721px;
    }

    .benefits-item {
        width: 248.978px;
        height: 249.348px;
    }

    .benefits-list {
        max-width: 700px;
    }

    .benefits-item-front {
        padding: 11px;
    }

    .benefits-item-back {
        padding: 11px;
    }
}

@media screen and (max-width: 700px) {
    .why-we-title {
        font-size: 29.651px;
    }

    .middle-conteiner {
        padding-top: 30px;
    }

    .why-we-description {
        font-size: 13.953px;
    }

    .why-we-bottom-container {
        padding-top: 38px;
        padding-right: 34px;
    }

    .why-we-bottom-title {
        font-size: 29.651px;
    }

    .why-we-bottom-proposition {
        font-size: 13.953px;
        max-width: 350px;
    }

    .why-button-container {
        width: 238.605px;
        height: 58.256px;
    }

    .whywe-button {
        width: 238.605px;
        height: 58.256px;
        font-size: 20px;
    }

    .benefits-item {
        width: 146.458px;
        height: 146.675px;
    }

    .benefits-list {
        max-width: 400px;
    }

    .benefits-item-front {
        padding: 6px;
    }

    .benefits-item-back {
        padding: 6px;
    }

    .benefits-item-back-title {
        font-size: 17.476px;
        margin-bottom: 2px;
    }

    .benefits-item-back-text {
        font-size: 10.577px;
    }
}

@media screen and (max-width: 550px) {
    .why-we-bottom-container {
        margin-top: 20px;
    }
}

@media screen and (max-width: 450px) {
    .middle-conteiner {
        padding-top: 0px;
    }
}

@media screen and (max-width: 430px) {
    .why-we-bottom-container {
        padding-left: 10px;
    }

    .why-we-bottom-title {
        font-size: 25px;
    }

    .benefits-item-back-title {
        font-size: 17px;
    }

    .benefits-item-back-text {
        font-size: 9px;
    }
}

@media screen and (max-width: 360px) {
    .why-we-bottom-container {
        padding-left: 10px;
    }

    .why-we-bottom-title {
        font-size: 20px;
    }

    .why-we-bottom-proposition {
        font-size: 12px;
    }
}

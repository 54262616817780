.share-title {
    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.share-description {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.share-text {
    color: #fff;

    font-family: Arimo;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 1530px) {
    .share .slick-slider,
    .share .slick-track {
        height: 200px;
    }

    .share-title {
        font-size: 45px;
    }

    .share-description {
        font-size: 22.5px;
    }

    .share-text {
        font-size: 18.75px;
    }
}

@media screen and (max-width: 1150px) {
    .share {
        /* padding: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding-top: 100px;
    }

    .share .slick-slider {
        width: 100%;
        max-width: 100%;
    }

    .share-title {
        padding: 0;
        text-align: center;
    }

    .share-description {
        padding: 0;
        text-align: center;
    }

    .share-slider {
        padding: 0;
        max-width: 100%;
    }

    .share-text {
        text-align: center;
    }

    .share .slick-next {
        top: 400px;
        right: 30%;
    }
    .share .slick-prev {
        top: 400px;
        left: 30%;
    }
}

@media screen and (max-width: 820px) {
    .share-title {
        font-size: 50.407px;
        max-width: 500px;
    }

    .share-description {
        display: none;
    }

    .share-text {
        font-size: 19.686px;
        max-width: 655px;
        text-align: start;
        padding: 0 15px;
    }
}

@media screen and (max-width: 630px) {
    .share-title {
        font-size: 29.651px;
    }

    .share-text {
        color: #fff;

        font-family: Arimo;
        font-size: 11.58px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .share .slick-next {
        top: 320px;
        right: 15%;
    }
    .share .slick-prev {
        top: 320px;
        left: 15%;
    }
}

@media screen and (max-width: 450px) {
    .share {
        padding-top: 70px;
    }

    .share-title {
        font-size: 29.651px;
    }

    .share-text {
        color: #fff;

        font-family: Arimo;
        font-size: 11.58px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .share .slick-next {
        top: 320px;
        right: 15%;
    }
    .share .slick-prev {
        top: 320px;
        left: 15%;
    }
}

.button__container {
    box-shadow: 0px 0px 73.099px 0px #dcb92d;
    transition: all 0.5s;
    width: 100%;
}

.button {
    line-height: 1;
    width: 100%;
    position: relative;
    background: linear-gradient(86deg, rgba(255, 204, 0) -3.99%, #fd5 98.98%);
    /* box-shadow: 0px 1.395px 1.395px 0px rgba(0, 0, 0, 0.25),
    1.744px 1.744px 3.488px 0px rgba(0, 0, 0, 0.55); */

    transition: all 0.5s;
}

.button span {
    font-family: Arimo;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: #fff;
    text-shadow: 1.744px 1.744px 3.488px rgba(0, 0, 0, 0.55);
}
/* 
.button:hover {
  transition: all 0.5s;

  background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
  box-shadow: 0px 0px 10.86px 0px #000 inset;
}

.button:hover span {
  transition: all 0.5s;

  color: #ffd000;
} */

@media (hover: hover) {
    .button__container:hover {
        box-shadow: none;
        transition: all 0.5s;
    }

    .button:hover {
        transition: all 0.5s;

        background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
        box-shadow: 0px 0px 10.86px 0px #000 inset;
    }

    .button:hover span {
        transition: all 0.5s;

        color: #ffd000;
    }
}

.treker-title,
.treker-description {
    font-family: Arimo;
}

@media screen and (max-width: 1500px) {
    .treker {
        padding-top: 350px;
    }
}

@media screen and (max-width: 1400px) {
    .treker {
        padding-top: 200px;
        padding-right: 70px;
    }
}

@media screen and (max-width: 1150px) {
    .treker {
        padding: 0;
        align-items: flex-start;
        padding-left: 66px;
        padding-top: 1200px;
    }

    .treker-title {
        text-align: start;
    }

    .treker-description {
        text-align: start;
    }
}

@media screen and (max-width: 950px) {
    .treker {
        padding-top: 1000px;
    }
}

@media screen and (max-width: 800px) {
    .treker {
        padding: 0;
        align-items: flex-start;
        padding-left: 66px;
        padding-top: 800px;
    }

    .treker-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 50.407px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .treker-description {
        color: #fff;

        font-family: Arimo;
        font-size: 23.721px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        /* text-transform: uppercase; */
    }

    .treker-button {
        width: 405.628px;
        height: 99.035px;
        font-size: 49.656px;
    }
}

@media screen and (max-width: 541px) {
    .treker {
        max-width: 250px;
        margin: 0;
        padding-top: 600px;
        padding-left: 35px;
    }

    .treker-title {
        font-size: 29.651px;
    }

    .treker-description {
        font-size: 13.953px;
    }

    .treker-button {
        width: 238.605px;
        height: 58.256px;
        font-size: 29.209px;
    }
}

@media screen and (max-width: 541px) {
    .treker {
        padding-top: 500px;
    }
}

@media screen and (max-width: 451px) {
    .treker {
        padding-top: 480px;
        padding-bottom: 0px;
    }
}

@media screen and (max-width: 400px) {
    .treker {
        padding-top: 420px;
        padding-bottom: 0px;
    }
}

/* @media screen and (max-width: 400px) {
  .treker {

    padding-top: 310px;
    padding-bottom: 0px;
  }

  
} */

@media screen and (max-width: 389px) {
    .treker {
        padding-top: 400px;
        padding-bottom: 0px;
    }
}

.header {
    background: linear-gradient(90deg, #161616 0%, #242424 100%);
    height: 110px;
    position: fixed;
    display: flex;
    align-items: center;
}

.header-desktop {
    display: flex;
    position: fixed;
    /* background: linear-gradient(90deg, #161616 0%, #242424 100%); */
}

.header-mobile {
    display: none;
    position: fixed;
    height: 124px;
    width: 105%;
    /* background: linear-gradient(90deg, #161616 0%, #242424 100%); */
}

.header-modile-img {
    max-height: 55px;
}

.header-mobile-menu {
    flex-direction: column;
    position: fixed;
    width: 105%;
    height: 50vh;
    top: 110px;
    left: 0;
    background: linear-gradient(180deg, #1f1e1e 0.19%, #101010 98.53%);

    box-shadow: 0px 1.395px 20.477px 0px rgba(0, 0, 0, 0.55) inset;
}

@media screen and (max-width: 1150px) {
    .header-desktop {
        display: none;
    }

    .header-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 34px;
    }

    .header-mobile-menu {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header-mobile-menu .menu-item {
        margin-bottom: 34px;
        background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .header-mobile-menu .menu-item a {
        background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .header-mobile-toggle-wrap {
        width: 55px;
        height: 23px;
    }
}

@media screen and (max-width: 700px) {
    .header-mobile {
        max-height: 70px;
    }

    .header-mobile-menu {
        top: 105px;
    }
}

.slide {
    background: linear-gradient(272deg, #131211 -2.3%, #1f1f1f 100.16%);
    box-shadow: 6.81px 6.81px 6.81px 0px rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(4.381069660186768px);
}
.slide-bottom {
    padding: 20px 30px;
}

.slide-bottom-not-active {
    padding: 0px;
}

.slide-video {
    width: 532px;
    height: 532px;
}

.slide-video-active {
    width: 532px;
    height: 792px;
}

.slide-button {
    box-shadow: none;
    width: 427.926px;
    height: 93.266px;
    font-size: 54.862px;
    text-transform: uppercase;
}

@media screen and (max-width: 1600px) {
    .slide {
        max-width: 399px;
    }

    .reviews-slide-questin {
        font-size: 25px;
    }

    .reviews-slide-answer {
        font-size: 20px;
    }

    .slide-title {
        font-size: 25px;
    }

    .slide-button {
        width: 320px;
        height: 93.266px;
        font-size: 40px;
    }
}

@media screen and (max-width: 1201px) {
    .slide-reviews .slick-prev {
        left: 20%;
    }

    .slide-reviews .slick-next {
        right: 20%;
    }

    .slide {
        max-width: 532px;
    }

    .reviews-slide-questin {
        font-size: 30px;
    }

    .reviews-slide-answer {
        font-size: 27px;
    }

    .slide-title {
        font-size: 30px;
    }

    .slide-button {
        width: 427.926px;
        height: 93.266px;
        font-size: 54px;
    }
}

@media screen and (max-width: 870px) {
    .slide-reviews .slick-prev {
        left: 10%;
    }

    .slide-reviews .slick-next {
        right: 10%;
    }

    .slide {
        max-width: 532px;
    }

    .reviews-slide-questin {
        font-size: 30px;
    }

    .reviews-slide-answer {
        font-size: 27px;
    }

    .slide-title {
        font-size: 30px;
    }

    .slide-button {
        width: 427.926px;
        height: 93.266px;
        font-size: 54px;
    }
}

@media screen and (max-width: 750px) {
    .slide-reviews .slick-track {
        /* height: 800px; */
        max-height: 920px;
    }

    .slide-reviews .slide-bottom{
        padding: 20px 20px;
    }


    .slide-reviews .slick-prev {
        left: 10%;
    }

    .slide-reviews .slick-next {
        right: 10%;
    }

    .slide {
        max-width: 319px;
        background: linear-gradient(272deg, #131211 -2.3%, #1f1f1f 100.16%);
        box-shadow: 6.827px 6.827px 6.827px 0px rgba(0, 0, 0, 0.55);
        backdrop-filter: blur(4.392241477966309px);
    }

    .reviews-slide-questin {
        font-size: 14px;
        background: linear-gradient(105deg, #e0b50c 0%, #997d09 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .reviews-slide-answer {
        font-size: 16px;
        font-weight: 700;
        background: linear-gradient(91deg, #e0b50c 0%, #997d09 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .slide-title {
        font-size: 18.5px;
        background: linear-gradient(90deg, #e0b50c 0%, #987c09 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }

    .slide-button {
        width: 257px;
        height: 56.013px;
        font-size: 32.949px;
    }
}

@media screen and (max-width: 750px) {
    .slide-reviews .slick-prev {
        left: 5%;
    }

    .slide-reviews .slick-next {
        right: 5%;
    }

    .slide {
        max-width: 300px;
    }
}

@media screen and (max-width: 400px) {
    .slide-reviews .slick-prev {
        left: 0%;
    }

    .slide-reviews .slick-next {
        right: 0%;
    }

    .slide {
        max-width: 300px;
    }
}


@media (max-width: 1201px) {
    .custom-slide-top{
        max-height: 690px;
    }
}
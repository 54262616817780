.big-modal-slide-two-list {
    display: flex;
    margin-bottom: 40px;
    border: 4px solid #fff;
    background-color: #000;
}

.big-modal-slide-two-item {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.big-modal-slide-two-img {
    width: 100%;
    height: 105%;
}

.big-modal-slide-two-item-active,
.big-modal-slide-two-item-hover {
    width: 100%;
    height: 105%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s;
}

.big-modal-slide-two-item-active-active {
    opacity: 1;
}

.big-modal-slide-two-item:hover .big-modal-slide-two-item-hover {
    opacity: 1;
    transition: all 0.5s;
    width: 100%;
    height: 105%;
}

@media screen and (max-width: 1000px) {
    .big-modal-slide-two-list {
        flex-direction: column;
        max-height: 596px;
        height: 100%;
        justify-content: space-between;
    }

    .big-modal-slide-two-item {
        width: 100%;
        height: 200px;
    }

    .big-modal-slide-two-img {
        width: 100%;
        height: 100%;
    }

    .big-modal-slide-two-item-active {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .big-modal-slide-two-list {
        flex-direction: column;
        max-height: 596px;
        height: 100%;
        justify-content: space-between;
        overflow: hidden;
    }

    .big-modal-slide-two-item {
        width: 100%;
        height: 200px;
    }

    .big-modal-slide-two-item-active {
        width: 100%;
        height: 100%;
    }

    .big-modal-slide-button-back {
        top: 30px;
        right: 30px;
        width: 35px;
        height: 35px;
    }
}

@media screen and (max-width: 500px) {
    .big-modal-slide-two-item {
        height: 100px;
    }
}

@media screen and (max-width: 449px) {
    .big-modal-slide-button-back {
        top: 10px;
        right: 20px;
    }
}

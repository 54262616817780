.service-list {
    background: linear-gradient(
        180deg,
        rgba(101, 101, 101, 0.63) 100%,
        #0c0c0c 0%
    );

    padding: 68px;
    position: relative;
    max-width: 781px;
    top: -250px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}

.car-service-item,
.car-service-title-item {
    font-family: Arimo;
}

@media screen and (max-width: 1600px) {
    .services-img {
        max-width: 900px;
    }

    .service-list {
        max-width: 586px;
        top: -200px;
    }

    .service-item {
        color: #fff;

        font-family: Arimo;
        font-size: 40.16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .service-item-service {
        color: #fff;

        font-family: Arimo;
        font-size: 40.16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

@media screen and (max-width: 1030px) {
    .services-img {
        max-width: 684px;
    }

    .service-list {
        max-width: 445px;
        top: -150px;
    }

    .service-item {
        color: #fff;

        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .service-item-service {
        color: #fff;

        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}

@media screen and (max-width: 710px) {
    .services-img {
        max-width: 410px;
    }

    .service-list {
        top: -80px;
        max-width: 265px;
        padding: 23px 50px;
    }

    .service-item {
        font-size: 17px;
        margin-bottom: 17px;
    }

    .service-item-service {
        font-size: 17px;
    }
}

@media screen and (max-width: 420px) {
    .services-img {
        max-width: 310px;
    }

    .service-list {
        top: -80px;
        max-width: 265px;
        padding: 23px 50px;
    }

    .service-item {
        font-size: 17px;
    }

    .service-item-service {
        font-size: 17px;
    }
}

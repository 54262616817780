.service-title,
.service-reduce-title {
    color: #ececec;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.service-description,
.service-reduce-description {
    color: #fff;

    text-align: center;
    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

@media screen and (max-width: 1600px) {
    .service-reduce {
        top: -120px;
    }
}

@media screen and (max-width: 1401px) {
    .service-title {
        display: none;
    }

    .service-description {
        display: none;
    }
}

@media screen and (max-width: 1030px) {
    .service-title {
        display: none;
    }

    .service-description {
        display: none;
    }

    .service-reduce {
        display: none;
    }
}

.big-modal-form-wrapper {
    padding: 10px;
}

.big-modal-form-close {
    width: 80px;
    height: 80px;
}

@media screen and (max-width: 1500px) {
    .five .big-modal-slide-title {
        max-width: 70%;
    }
}

@media screen and (max-width: 1200px) {
    .big-modal-form-close {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 600px) {
    .big-modal-form-container {
        height: 250px;
        position: relative;
    }

    .newInputStyle {
        background: linear-gradient(
            90deg,
            rgba(244, 244, 244, 0.25) 100%,
            rgba(102, 102, 102, 0.25) 0%
        ) !important;
        border-radius: 0 !important;
    }

    .big-modal-form-button {
        width: 90% !important;
        font-size: 30px !important;
        height: 60px !important;
        position: absolute !important;
        bottom: -100px !important;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0 !important;
        color: #fff !important;
        background: linear-gradient(
            86deg,
            rgba(255, 204, 0, 0.65) -3.99%,
            #fd5 98.98%
        ) !important;
        box-shadow:
            0px 1.395px 1.395px 0px rgba(0, 0, 0, 0.25),
            1.744px 1.744px 3.488px 0px rgba(0, 0, 0, 0.55) !important;
    }

    .big-modal-form-button:active {
        background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
        box-shadow: 0px 0px 10.86px 0px #000 inset;
        color: #fff !important;

        background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media screen and (max-width: 750px) {
    .big-modal-form-sucses-title {
        font-size: 34.884px;
    }

    .big-modal-form-text-top {
        font-size: 13.428px;
    }

    .big-modal-form-link {
        width: 348.14px;
        height: 58.256px;
        font-size: 29.209px !important;
    }

    .big-modal-form-text-bottom {
        font-size: 13.428px;
    }

    .big-modal-form-close {
        width: 28.919px;
        height: 28.919px;
    }

    .big-modal-form-link {
        width: 348.14px;
        height: 58.256px;
        font-size: 29.209px;
    }
}

@media screen and (max-width: 448px) {
    .big-modal-form-close {
        top: 20px;
        right: 20px;
        /* width: 28.919px;
    height: 28.919px; */
    }

    .big-modal-form-link {
        width: 248.14px;
        height: 58.256px;
        font-size: 25px !important;
    }
}

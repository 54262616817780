.big-modal-slide-four-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.big-modal-slide-one-item-price {
    color: #fff;

    font-family: Arimo;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
    z-index: 100;
}

.big-modal-slide-one-img-active {
    position: absolute;
    top: 0;
    left: 0;
    width: 103%;
    height: 100%;
    transition: all 0.5s;
}

@media screen and (max-width: 1500px) {
    .big-modal-slide-one-item-price {
        font-size: 30px;
    }
}

@media screen and (max-width: 1000px) {
    .four .big-modal-slide-one-item-active-top,
    .four .big-modal-slide-one-item-active-bottom {
        border: 1.392px solid #fd5;
        background: linear-gradient(180deg, #fc0 0%, rgba(0, 0, 0, 0) 100%);
    }
}

@media screen and (max-width: 640px) {
    .four .big-modal-slide-title {
        font-size: 27px;
    }

    .big-modal-slide-one-item-price {
        font-size: 20px;
    }
}

@media screen and (max-width: 435px) {
    .four .big-modal-slide-title {
        font-size: 22px;
    }

    .big-modal-slide-one-item-price {
        font-size: 18px;
    }
}

.big-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 199;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
}

.big-modal-container::-webkit-scrollbar {
    width: 0;
}

.big-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    background: linear-gradient(180deg, #000 0%, #2e2e2e 100%);
    max-width: 1920px;
    width: 100%;
    /* height: 95vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}

.big-modal-container .slick-slider {
    width: 100%;
    height: 100%;
}

.big-modal-slide-container::-webkit-scrollbar {
    width: 0;
}

.big-modal-slide-container {
    padding: 83px 48px;
    width: 100%;
    height: 95vh;
    position: relative;
    overflow-y: scroll;
}

.big-modal-slide-title {
    color: #fff;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.big-modal-slide-text {
    color: #fff;
    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 26px;
}

.big-modal-button {
    max-width: 876px;
    color: #fff;

    font-family: Arimo;
    font-size: 33.239px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    background: #1e1e1e;
    text-align: left;
    width: 100%;
    max-width: 876px;
    height: 80px;
    padding-left: 30px;
    cursor: pointer;
}

.big-modal-button:hover {
    background: linear-gradient(
            90deg,
            rgba(229, 229, 229, 0.39) 0%,
            rgba(225, 225, 225, 0) 78.5%
        ),
        #1e1e1e;
}

.big-modal-button-active {
    background: linear-gradient(
            90deg,
            rgba(255, 208, 0, 0.5) 0%,
            rgba(255, 208, 0, 0) 84%
        ),
        #1e1e1e;
}

.big-modal-slide-button-back {
    /* padding: 83px 48px; */
    position: absolute;
    top: 93px;
    right: 50px;
    transform: rotate(180deg);
}

.big-modal-slide-button-back:disabled {
    opacity: 0.5;
}

.big-modal-button-next {
    background: #1e1e1e;
    color: #fff;

    font-family: Arimo;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    max-width: 300px;
    width: 100%;
    height: 80px;
    transition: all 0.3s;
}

.big-modal-button-next:hover {
    background: #fc0;
    transition: all 0.3s;
}

.big-modal-button-next:disabled,
.big-modal-button-next:disabled .big-modal-button-next:hover {
    opacity: 0.5;
    background: #1e1e1e;
    box-shadow: none;
}

.big-modal-slide-one-list {
    display: flex;
    flex-wrap: wrap;
    border: 4px solid #fff;
    margin-bottom: 40px;
    justify-content: space-between;
}

.big-modal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.big-modal-slide-one-item {
    position: relative;
    width: 100%;
    max-width: 605px;
    height: 299px;
    cursor: pointer;
}

.big-modal-slide-one-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.big-modal-slide-one-item-hover-top {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #737373 0%, rgba(0, 0, 0, 0) 100%);
    transition: all 0.5s;
}

.big-modal-slide-one-item-hover-bottom {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #737373 100%);
    transition: all 0.5s;
}

.big-modal-slide-one-item:hover .big-modal-slide-one-item-hover-top,
.big-modal-slide-one-item:hover .big-modal-slide-one-item-hover-bottom {
    position: absolute;
    opacity: 1;
    transition: all 0.5s;
}

.big-modal-slide-one-item-active-top {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #ffc400 0%, rgba(0, 0, 0, 0) 100%);
    transition: all 0.5s;
    z-index: 99;
}

.big-modal-slide-one-item-active-bottom {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #ffc400 100%);
    transition: all 0.5s;
    z-index: 99;
}

.big-modal-form-sucses-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.big-modal-form-container {
    /* position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
    z-index: 99;
    border: 4px solid #d9d9d9;
    margin-top: 45px;

    /* background: linear-gradient(180deg, #000 0%, #2e2e2e 100%); */

    width: 100%;
    height: 100%;
    max-height: 616px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.big-modal-form-wrapper {
    max-width: 827px;
    width: 100%;
}

.big-modal-form-title {
    color: #fff;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.big-modal-form-sucses-title {
    color: #fff;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    max-width: 998px;
    text-align: center;
}

.big-modal-form-text {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;

    margin-bottom: 126px;
}

.big-modal-form-text-top {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 50px;

    max-width: 645px;
}

.big-modal-form-text-bottom {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    /* margin-bottom: 126px; */
    margin-top: 55px;
    max-width: 529px;
}

.big-modal-form-close {
    position: absolute;
    top: 80px;
    right: 80px;
    cursor: pointer;
}

.big-modal-form-button {
    background: linear-gradient(
        90deg,
        #414141 0%,
        rgba(156, 156, 156, 0.41) 100%
    ) !important;
    box-shadow: -3px 4px 7.2px 1px rgba(0, 0, 0, 0.38);
    color: #fff !important;

    font-family: Arimo;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: uppercase;

    max-width: 827px;
    width: 100%;
    height: 96px !important;
    transition: all 0.5s;
}

.big-modal-form-button:hover {
    background: linear-gradient(
        86deg,
        #212121 -3.99%,
        #111110 98.98%
    ) !important;
    box-shadow: 0px 0px 18.25px 0px #000 inset;
    color: #f4c60d !important;
    /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    transition: all 0.5s;
}

.big-modal-form-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        86deg,
        rgba(255, 204, 0, 0.65) -3.99%,
        #fd5 98.98%
    );

    box-shadow: 0px 0px 40px 0px #ffd000;
    color: #fff !important;

    font-family: Arimo;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;

    max-width: 827px;
    width: 100%;
    height: 96px !important;
    transition: all 0.5s;
}

.big-modal-form-link:hover {
    background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);

    box-shadow: 0px 0px 18.25px 0px #000 inset;
    color: #f4c60d !important;
    /* background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
    transition: all 0.5s;
}

@media screen and (max-width: 1919px) {
    .big-modal-container {
        max-width: 1700px;
    }

    .big-modal-slide-one-item {
        max-width: 532px;
    }
}

@media screen and (max-width: 1700px) {
    .big-modal-container {
        max-width: 1500px;
    }

    .big-modal-slide-one-item {
        max-width: 463px;
    }
}

@media screen and (max-width: 1500px) {
    .big-modal-container {
        max-width: 1000px;
    }

    .big-modal-slide-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .big-modal-slide-one-item {
        max-width: 298px;
        max-height: 200px;
    }

    .big-modal-button {
        font-size: 25px;
        max-width: 50%;
    }
}

@media screen and (max-width: 1000px) {
    .big-modal-container {
        max-width: 100%;
        height: 100%;
    }

    .big-modal-slide-title {
        font-size: 40px;
    }

    .big-modal-slide-text {
        font-size: 25px;
    }

    .big-modal-button-next {
        font-size: 25px;
        max-width: 200px;
        height: 80px;
    }

    .big-modal-slide-one-item {
        max-width: 50%;
        max-height: 200px;
    }

    .big-modal-button {
        font-size: 17px;
        max-width: 60%;
    }
}

@media screen and (max-width: 640px) {
    .big-modal-slide-container {
        justify-content: space-between;
        padding: 30px;
        height: 75%;
        position: relative;
        top: 50%;
        /* transform: translateY(-50%); */
    }

    .five {
        justify-content: flex-start;
    }

    .big-modal-container .slick-list,
    .big-modal-container .slick-slider {
        height: 80%;
    }

    .big-modal-slide-title {
        font-size: 34.884px;
        max-width: 300px;
    }

    .big-modal-slide-text {
        font-size: 17.442px;
    }

    .big-modal-button-next {
        font-size: 25px;
        width: 238.605px;
        height: 58.256px;
        height: 80px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        background: linear-gradient(
            86deg,
            rgba(255, 204, 0, 0.65) -3.99%,
            #fd5 98.98%
        );
        box-shadow: 0px 0px 13.953px 0px #ffd000;
        text-shadow: 1.744px 1.744px 5.233px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 29.209px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    .big-modal-button-next:active {
        background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
        box-shadow: 0px 0px 10.86px 0px #000 inset;

        background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .big-modal-slide-one-item {
        max-width: 50%;
        /* width: 190.116px; */
        height: 107.791px;
    }

    .big-modal-button {
        padding: 0;
        text-align: center;
        font-size: 17px;
        max-width: 100%;
        height: 35px;
        margin-bottom: 47px;
    }

    .slide-buttons {
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 449px) {
    .big-modal-slide-container {
        padding: 30px;
        height: 615px;
        padding: 10px 20px;
    }

    .big-modal-slide-container .slick-slider {
        height: 700px;
    }

    .big-modal-slide-one-list {
        margin-bottom: 15px;
    }

    .big-modal-slide-title {
        font-size: 27.884px;
    }

    .big-modal-slide-text {
        font-size: 17.442px;
    }

    .big-modal-button-next {
        font-size: 25px;
        width: 238.605px;
        height: 58.256px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        text-shadow: 1.744px 1.744px 5.233px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 29.209px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }

    .big-modal-slide-one-item {
        max-width: 50%;
        width: 190.116px;
        height: 107.791px;
    }

    .big-modal-button {
        font-size: 17px;
        max-width: 100%;
        height: 35px;
        margin-bottom: 15px;
    }

    .slide-buttons {
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 1400px) {
    .review {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 1200px) {
    .review-text-wrapper {
        left: 60px;
    }
}

@media screen and (max-width: 870px) {
    .review-text-wrapper {
        left: 60px;
    }

    .review-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 50.407px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .review-description {
        color: #fff;

        font-family: Arimo;
        font-size: 23.721px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        max-width: 500px;
    }
}

@media screen and (max-width: 750px) {
    .review-text-wrapper {
        left: 35px;
    }

    .review-title {
        color: #ececec;

        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .review-description {
        color: #fff;

        font-family: Arimo;
        font-size: 13.953px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .review-text-wrapper {
        left: 0px;
    }

    .review-title {
        max-width: 400px;
        padding: 0 20px;
    }

    .review-description {
        max-width: 400px;
        padding: 0 20px;
    }
}

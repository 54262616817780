.header-bg {
    background: linear-gradient(90deg, #0d0c0c 0%, #262424 100%) !important;
}

.menu-link {
    background: linear-gradient(180deg, #ececec 0%, #b9b9b9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
}

@property --a {
    syntax: '<color>';
    inherits: false;
    initial-value: #ececec;
}

@property --b {
    syntax: '<color>';
    inherits: false;
    initial-value: #b9b9b9;
}

.menu-link-animation {
    display: block;
    background-image: linear-gradient(180deg, var(--a) 0%, var(--b) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: all 0.5s ease;
    opacity: 0.3;
}

.menu-link-animation-white {
    display: block;
    background-image: linear-gradient(180deg, #ececec 0%, #b9b9b9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    transition: all 0.5s ease;

    opacity: 1;
}

/* Slider Start */
.slide-reviews {
    /* width: 90%; */
    overflow: hidden;
    margin: 0 auto;
}

.slide-reviews .slick-slider {
    position: relative;
}

.slide-reviews .slick-track {
    /* display: flex; */
    /* align-items: center; */
    height: 1200px;
}

@media (min-width: 1601px) {
    .slide-reviews .slick-track {
        height: 1450px;
    }
}

.slide-reviews .slick-slide {
    width: 532px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    transform: scale(0.8);
    transition: all 0.3s;
    filter: blur(6px);
}

.slide-reviews .slick-current {
    transition: all 0.3s;
    transform: scale(1);
    filter: blur(0px);
}

.slide-reviews .slick-center,
.slide-reviews .slick-current-end {
    transition: all 0.3s;
    transform: scale(1);
    filter: blur(0px);
}

.slide-reviews .slick-arrow {
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}

.slide-reviews .slick-prev {
    position: absolute;
    left: 31%;
    width: 67px;
    height: 90px;
}

.slide-reviews .slick-next {
    position: absolute;
    right: 31%;
    width: 67px;
    height: 90px;
}

.slide-reviews .slick-prev::before {
    content: '';
    font-size: 0;
    width: 75px;
    height: 75px;
    display: block;
    /* left: 40%; */

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/image/review/arrow-left.png);
    background-size: 67px;
}

.slide-reviews .slick-next::before {
    content: '';
    font-size: 0;
    width: 67px;
    height: 90px;
    display: block;
    /* left: 40%; */

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/image/review/arrow-right.png);
    background-size: 67px;
}

/* Slider End */

/* Benefits Start */

.benefits-top {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.65) 0%, #737373 100%);
}

.benefits-bottom {
    background: linear-gradient(180deg, #737373 0%, rgba(0, 0, 0, 0.65) 100%);
}

.benefits-item:hover .benefits-front {
    opacity: 0;
}

.benefits-item .benefits-back {
    opacity: 0;
}

.benefits-item:hover .benefits-back {
    opacity: 1;
}

.benefits-button {
    box-shadow: 0px 0px 118px 0px rgba(255, 255, 255, 0.18);
    background: linear-gradient(88deg, #171717 -3.2%, #848484 257.78%);
    transition: all 0.5s;
}

.benefits-button:hover {
    background: #ffd000;
    box-shadow: 0px 0px 43.2px 8px #cbac21;
    transition: all 0.5s;
}

.treker-button {
    width: 100%;
    background: linear-gradient(
        86deg,
        rgba(255, 204, 0, 0.65) -3.99%,
        #fd5 98.98%
    );

    color: #fff;
    box-shadow: 0px 0px 26.316px 0px #ffd000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}

.treker-button span {
    text-shadow: 3.289px 3.289px 9.868px rgba(0, 0, 0, 0.95);
    background: linear-gradient(267deg, #fff -17.64%, #d6d6d6 176.01%);
    background-clip: text;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    transition: all 0.5s;
}

.treker-button:hover {
    background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);
    box-shadow: 0px 0px 20.461px 0px #000 inset;
    transition: all 0.5s;
    /* background: #ffd000;
  box-shadow: 0px 0px 43.2px 8px #cbac21;
  transition: all 0.5s; */
}

.treker-button:hover span {
    transition: all 0.5s;
    background: linear-gradient(88deg, #f4c60d -20.75%, #8e7308 107.36%);
    background-clip: text;
    -webkit-background-clip: text;
}

/* Benefits End */

/* Share Slider start */

.share-slider {
    /* max-width: 1110px; */
    max-width: 900px;
    padding-left: 150px;
    /* overflow: hidden; */
}

.share-slider .slick-track {
    display: flex !important;
    align-items: center;
    height: 300px;
}

.share-slider .slick-slide {
    display: flex !important;
    align-items: center;
    width: 350px !important;
    transform: scale(0.7);
    transition: all 0.5s;
    filter: blur(5px);
}
/* 
.share-slider .slick-active {
  transform: scale(0.7);
  /* width: 250px !important; */
/* transition: all 0.5s; */
/* } */

/* .share-slider .slick-active {
  transition: all 0.5s;
  transform: scale(1);
  filter: blur(0);
} */

.share-slider .slick-current {
    filter: blur(0);
    transform: scale(1);

    transition: all 0.5s;
}

.share-slider .slick-center {
    transform: scale(1);

    transition: all 0.5s;
    filter: blur(0);
}

.share-slider .slick-active {
    /* transform: scale(1); */
    filter: blur(0);
    transition: all 0.5s;
}

.share-slider .slick-arrow {
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}

.share-slider .slick-prev {
    position: absolute;
    left: -2%;
    width: 67px;
    height: 90px;
}

.share-slider .slick-next {
    position: absolute;
    right: -2%;
    width: 67px;
    height: 90px;
}

.share-slider .slick-prev::before {
    content: '';
    font-size: 0;
    width: 67px;
    height: 90px;
    display: block;
    /* left: 40%; */

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/image/review/arrow-left.png);
    background-size: 67px;
}

.share-slider .slick-next::before {
    content: '';
    font-size: 0;
    width: 75px;
    height: 75px;
    display: block;
    /* left: 40%; */

    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../assets/image/review/arrow-right.png);
    background-size: 67px;
}

/* Share Slider end */

/* CarService Start */

.carservice-list {
    background: linear-gradient(
        180deg,
        #171717 0%,
        rgba(91, 91, 91, 0.53) 100%
    );
}

/* CarService End */


/*custom video player*/

.slide-reviews .plyr__control--overlaid{
    background: rgba(255, 204, 1);
}

.slide-reviews .plyr--full-ui input[type=range]{
    color: rgba(255, 204, 1);
}

.slide-reviews .plyr--video .plyr__control:focus-visible,
.slide-reviews .plyr--video .plyr__control:hover,
.slide-reviews .plyr--video .plyr__control[aria-expanded=true]{
    background: rgba(255, 204, 1);
}

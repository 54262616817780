.button-main {
    max-width: 500px;
    width: 100%;
    height: 123px;
    font-size: 61.209px;
    text-transform: uppercase;
    /* background: linear-gradient(86deg, #FC0 -3.99%, #FD5 98.98%);
  box-shadow: 0px 2.924px 2.924px 0px rgba(0, 0, 0, 0.25), 3.655px 3.655px 7.31px 0px rgba(0, 0, 0, 0.55); */
}

.main-back-1920 {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    /* height: 100%; */
}

.main-back-middle {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.middle-conteiner {
    padding-top: 120px;
}

.main-container {
    position: relative;
    z-index: 99;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    /* justify-content: center;  */
}

@media screen and (max-width: 1765px) {
    .main-container {
        padding-top: 50px;
    }
}

@media screen and (max-width: 1150px) {
    .main-back-middle {
        height: auto;
    }
}

/* .main {
  padding-top: 0;
}

 */

@media screen and (max-width: 1600px) {
    .main-container {
        max-width: 500px;
        top: 120px;
        left: 70px;
    }

    .main-title {
        font-size: 45px;
    }

    .main-text {
        font-size: 25px;
    }

    .button-main {
        max-width: 400px;
        height: 100px;
        font-size: 50.209px;
    }
}

@media screen and (max-width: 1525px) {
    .main-container {
        padding-top: 0px;
    }
}

@media screen and (max-width: 1400px) {
    .main-back-1920 {
        object-fit: cover;
    }
}

@media screen and (max-width: 1345px) {
    .main-container {
        /* top: 0px; */
        margin-top: -30px;
    }
}

@media screen and (max-width: 1230px) {
    .main-container {
        /* top: 0px; */
        margin-top: -50px;
    }
}

@media screen and (max-width: 1150px) {
    .main-back-1920 {
        height: 100%;
    }
    .main-container {
        position: absolute;
        top: auto;
        max-width: 621px;
        bottom: 120px;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        justify-content: flex-end;
        height: auto;
    }

    .main-title {
        color: #fff;

        text-align: center;
        text-shadow: 2.965px 2.965px 8.895px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 59.302px;
        font-style: normal;
        font-weight: 700;
        line-height: 98%;
    }

    .main-title span {
        color: #ffd000;
        font-family: Arimo;
        font-size: 65.826px;
        font-style: normal;
        font-weight: 700;
        line-height: 98%;
    }

    .main-text {
        color: #e2e2e2;

        text-align: center;
        text-shadow: 2.965px 2.965px 8.895px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-bottom: 66px;
    }

    .button-main {
        max-width: 405px;
        height: 100px;
        font-size: 49.656px;
    }
}

@media screen and (max-width: 700px) {
    .main-container {
        max-width: 366px;
        /* padding-bottom: 150px; */
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        justify-content: center;
    }

    .main-back-1920 {
        height: auto;
    }

    .main-back-middle {
        object-fit: fill;
    }

    .main-title {
        color: #fff;

        text-align: center;
        text-shadow: 1.744px 1.744px 5.233px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 34.884px;
        font-style: normal;
        font-weight: 700;
        line-height: 98%;
    }

    .main-title span {
        color: #ffd000;
        font-family: Arimo;
        font-size: 38.721px;
        font-style: normal;
        font-weight: 700;
        line-height: 98%;
    }

    .main-text {
        color: #e2e2e2;

        color: #e2e2e2;
        font-family: Arimo;
        font-size: 17.442px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: lowercase;
        margin-bottom: 66px;
    }

    .button-main {
        max-width: 238px;
        height: 58px;

        text-shadow: 1.744px 1.744px 3.488px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 29.209px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
}

@media screen and (max-width: 450px) {
    .main-container {
        justify-content: center;
    }

    .main-title {
        font-size: 27px;
    }
    .main-text {
        font-size: 15px;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Arimo', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

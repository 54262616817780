.preloader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.preloader-logo {
    margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
    .preloader-logo {
        max-width: 350px;
    }
}

@media screen and (max-width: 350px) {
    .preloader-logo {
        max-width: 300px;
    }
}

.small-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 199;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
}

.small-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;

    background: linear-gradient(180deg, #000 0%, #2e2e2e 100%);
    max-width: 1500px;
    width: 100%;
    height: 844px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.small-modal-wrapper {
    max-width: 827px;
    width: 100%;
}

.small-modal-title {
    color: #fff;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.small-modal-sucses-title {
    color: #fff;

    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    max-width: 998px;
    text-align: center;
}

.small-modal-text {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: lowercase;

    margin-bottom: 126px;
}

.small-modal-text-top {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 50px;

    max-width: 645px;
}

.small-modal-text-bottom {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;

    margin-top: 55px;
    max-width: 529px;
}

.small-modal-close {
    position: absolute;
    top: 80px;
    right: 80px;
    cursor: pointer;
}

.small-modal-button {
    background: linear-gradient(
        90deg,
        #414141 0%,
        rgba(156, 156, 156, 0.41) 100%
    ) !important;
    box-shadow: -3px 4px 7.2px 1px rgba(0, 0, 0, 0.38);
    color: #fff !important;

    font-family: Arimo;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    text-transform: uppercase;

    max-width: 827px;
    width: 100%;
    height: 96px !important;
    transition: all 0.5s;
}

.small-modal-button:hover {
    background: linear-gradient(
        86deg,
        #212121 -3.99%,
        #111110 98.98%
    ) !important;
    box-shadow: 0px 0px 18.25px 0px #000 inset;
    color: #f4c60d !important;
    transition: all 0.5s;
}

.small-modal-link {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        86deg,
        rgba(255, 204, 0, 0.65) -3.99%,
        #fd5 98.98%
    );

    box-shadow: 0px 0px 40px 0px #ffd000;
    color: #fff !important;

    font-family: Arimo;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;

    max-width: 827px;
    width: 100%;
    height: 96px !important;
    transition: all 0.5s;
}

.small-modal-link:hover {
    background: linear-gradient(86deg, #212121 -3.99%, #111110 98.98%);

    box-shadow: 0px 0px 18.25px 0px #000 inset;
    color: #f4c60d !important;
    transition: all 0.5s;
}

@media screen and (max-width: 1000px) {
    .small-modal-wrapper {
        max-width: 90%;
    }

    .small-modal-title {
        font-size: 40px;
    }

    .small-modal-sucses-title {
        font-size: 40px;
        text-align: center;
    }

    .small-modal-text {
        font-size: 20px;
    }

    .small-modal-text-top {
        text-align: center;
    }

    .small-modal-text-bottom {
        text-align: center;
    }

    .small-modal-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 325px !important;
        font-size: 27px !important;
        position: relative;
    }
}

@media screen and (max-width: 780px) {
    .small-modal-title {
        text-align: start;
        width: 100%;
        padding-left: 20px;
    }

    .small-modal-sucses-title {
        text-align: center;
        width: 100%;
        padding-left: 0px;
    }

    .small-modal-text {
        text-align: start;
        width: 100%;
        padding-left: 20px;
    }

    .small-modal-text-top {
        text-align: center;
        width: 100%;
        padding-left: 0px;
    }

    .small-modal-text-bottom {
        text-align: center;
        width: 100%;
        padding-left: 0px;
    }
}

@media screen and (max-width: 590px) {
    .small-modal-container {
        height: 700px;
    }

    .small-modal-sucses-title {
        font-size: 27px;
    }

    .small-modal-text {
        margin-bottom: 50px;
    }

    .small-modal-text-top {
        font-size: 20px;
        max-width: 300px;
    }

    .small-modal-text-bottom {
        font-size: 20px;
        max-width: 300px;
    }

    .small-modal-close {
        right: 40px;
        top: 40px;
    }

    .small-modal-button {
        width: 325px !important;
        font-size: 40px !important;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 58px !important;
    }
}

@media screen and (max-width: 500px) {
    .small-modal-container {
        height: 600px;
    }

    .small-modal-title {
        font-size: 25px;
    }

    .small-modal-sucses-title {
        font-size: 25px;
    }

    .small-modal-text {
        font-size: 15px;
    }

    .small-modal-text-top {
        font-size: 15px;
    }

    .small-modal-close {
        top: 25px;
        right: 25px;
        /* position: absolute;
    top: 80px;
    right: 80px;
    cursor: pointer; */
    }
}

@media screen and (max-width: 350px) {
    .small-modal-container {
        height: 600px;
    }

    .small-modal-title {
        font-size: 25px;
    }

    .small-modal-sucses-title {
        font-size: 25px;
    }

    .small-modal-text {
        font-size: 15px;
    }

    .small-modal-text-top {
        font-size: 15px;
    }

    .small-modal-close {
        top: 25px;
        right: 25px;
    }

    .small-modal-link {
        width: 300px !important;
        height: 70px !important;
        font-size: 20px !important;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0px;
}

.footer-top {
    padding: 49px 150px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg, #2c2c2c 0%, #121212 100%);
}

.footer-bottom {
    background: linear-gradient(90deg, #737373 0%, #2d2d2d 100%);
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70px;
    /* padding: 0 120px; */
    padding-right: 150px;
}

.footer-policy {
    margin-right: auto;
}

.footer-list {
    max-width: 380px;
    width: 100%;
}

.footer-item {
    font-size: 26px;
    color: #fff;
    margin-bottom: 50px;
    transition: all 0.5s;
}

.footer-item:hover {
    transition: all 0.5s;
    color: #ffd000;
    text-decoration: underline;
}

.footer-item:last-of-type {
    margin-bottom: 0;
}

.footer-logo {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}

.footer-policy {
    color: #fff;

    font-family: Arimo;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}

.footer-desctop {
    display: flex;
}

.footer-mobile {
    display: none;
}

@media screen and (max-width: 1400px) {
    .footer-desktop {
        display: none;
    }

    .footer-mobile {
        display: flex;
        flex-wrap: wrap;
    }

    .footer-item {
        display: flex;
        align-items: center;
    }

    .footer-office-wrap {
        width: 50%;
    }

    .footer-network-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 50%;
    }

    .footer-network-wrap .footer-item {
        justify-content: flex-end;
    }

    .footer-contact-wrap {
        width: 100%;
    }

    .footer-office-wrap .footer-list {
        align-items: flex-start;
    }

    .footer-contact-wrap {
        display: flex;
        flex-direction: column;

        align-items: center;
    }

    .footer-contact-wrap .footer-list {
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        justify-content: space-between;
    }

    .footer-item img {
        width: 30.488px;
        height: 30.488px;
        margin-right: 10px;
    }

    .footer-title-ofice {
        color: rgba(255, 255, 255, 0.1);

        font-family: Arimo;
        font-size: 26.621px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
    }

    .footer-title-network {
        color: rgba(255, 255, 255, 0.1);

        font-family: Arimo;
        font-size: 26.621px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
    }

    .footer-title-contact {
        color: rgba(255, 255, 255, 0.05);

        font-family: Arimo;
        font-size: 152.666px;
        font-style: normal;
        font-weight: 700;
        line-height: 0.7;
        text-transform: lowercase;
    }
    .footer-title-ofice {
        position: relative;
    }
}

@media screen and (max-width: 1200px) {
    .footer-bottom {
        justify-content: space-around;
        padding: 0 15px;
    }

    .footer-bottom a {
        max-width: 330px;
        width: 100%;
    }

    .footer-bottom a:nth-child(1) {
        display: flex;
        justify-content: center;
    }

    .footer-policy {
        order: 1;
        margin-right: 0;
        font-size: 22px;
    }
    .footer-policy {
        margin-right: 0;
    }
    .footer-bottom-logo {
        order: 2;
    }

    .developers {
        order: 3;
        font-size: 22px;
    }
}

@media screen and (max-width: 1150px) {
    .footer-bottom {
        justify-content: space-around;
        flex-direction: row;
    }
    .footer-policy {
        order: 1;
        margin-right: 0;
        font-size: 14px;
    }
    .footer-policy {
        margin-right: 0;
    }
    .footer-bottom-logo {
        order: 2;
    }

    .developers {
        order: 3;
        font-size: 14px;
    }
}

@media screen and (max-width: 1150px) {
    .footer-bottom .footer-logo {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0);
    }

    .footer-top {
        padding: 49px 70px;
    }
}

@media screen and (max-width: 867px) {
    .footer-mobile {
        padding: 50px;
    }

    .footer-item {
        font-size: 28px;
    }
}

@media screen and (max-width: 801px) {
    .footer-mobile {
        padding: 30px;
    }

    .footer-item {
        font-size: 28px;
    }
}

@media screen and (max-width: 760px) {
    .footer-network-wrap .footer-item {
        font-size: 13.953px;
    }

    .footer-item {
        font-size: 13.953px;
    }

    .footer-item img {
        width: 17.442px;
        height: 17.442px;
    }

    .footer-title-ofice {
        font-size: 15.23px;
    }

    .footer-title-network {
        font-size: 15.23px;
    }

    .footer-title-contact {
        font-size: 89.804px;
    }
}

@media screen and (max-width: 750px) {
    .footer-network-wrap .footer-item {
        font-size: 13.953px;
    }

    .footer-item {
        font-size: 13.953px;
    }

    .footer-item img {
        width: 17.442px;
        height: 17.442px;
    }

    .footer-title-ofice {
        font-size: 15.23px;
    }

    .footer-title-network {
        font-size: 15.23px;
    }

    .footer-title-contact {
        font-size: 89.804px;
    }
}

@media screen and (max-width: 700px) {
    .footer-item {
        margin-bottom: 25px;
    }

    .footer-policy {
        font-size: 13.953px;
    }
}

@media screen and (max-width: 500px) {
    .footer-bottom {
        width: auto;
    }

    .footer-bottom a:last-child {
        text-align: end;
    }

    .footer-policy {
        order: 1;
        margin-right: 0;
        font-size: 7px;
    }
    .footer-policy {
        margin-right: 0;
    }
    .footer-bottom-logo {
        order: 2;
    }

    .developers {
        order: 3;
        font-size: 7px;
    }
}

@media screen and (max-width: 440px) {
    .footer-mobile {
        padding: 20px;
    }

    .footer-item a {
        font-size: 12px;
    }

    .footer-item {
        margin-bottom: 14px;
    }

    .footer-contact-wrap .footer-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-title-ofice {
        font-size: 13px;
    }

    .footer-title-network {
        font-size: 13px;
    }

    .footer-title-contact {
        font-size: 50px;
    }

    .footer-bottom {
        padding: 0 10px;
    }
}

.feedBack-reduce {
    display: none;
}

@media screen and (max-width: 1750px) {
    .feedBack-form-title {
        color: #ececec;

        text-shadow: 0px 2.997px 2.997px rgba(0, 0, 0, 0.25);
        font-family: Arimo;
        font-size: 44.954px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .feedBack-form-description {
        color: #fff;

        text-shadow: -0.749px 0.749px 1.798px rgba(0, 0, 0, 0.8);
        font-family: Arimo;
        font-size: 22.477px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 500px;
    }

    .feedBack-form-img {
        max-width: 570px;
        max-height: 543px;
    }
}

@media screen and (max-width: 1600px) {
    .feedBack-form-title {
        color: #ececec;

        text-shadow: 0px 2.997px 2.997px rgba(0, 0, 0, 0.25);
        font-family: Arimo;
        font-size: 44.954px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .feedBack-form-description {
        color: #fff;

        text-shadow: -0.749px 0.749px 1.798px rgba(0, 0, 0, 0.8);
        font-family: Arimo;
        font-size: 22.477px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 500px;
    }

    .feedBack-form-img {
        max-width: 570px;
        max-height: 543px;
    }
}

@media screen and (max-width: 1401px) {
    .feedBack {
        flex-direction: column-reverse;
        align-items: center;
    }

    .feedBack-form-container {
        max-width: 619px;
    }

    .feedBack-form-img {
        margin-bottom: 50px;
        display: none;
    }
}

@media screen and (max-width: 1150px) {
    .feedBack {
        padding-bottom: 100px;
        background-color: #000;
    }
}

@media screen and (max-width: 1030px) {
    .feedBack {
        flex-direction: column;
        align-items: center;
        background-color: #000;
    }

    .feedBack-form-container {
        max-width: 100%;
    }

    .feedBack-form-container .button-submit {
        max-width: 100%;
    }

    .feedBack-form-img {
        margin-bottom: 50px;
        display: none;
    }

    .feedBack-reduce {
        display: flex;
        top: 0;
        margin-left: auto;
        align-items: flex-end;
        margin-top: 82px;
        margin-bottom: 82px;
    }

    .feedBack-reduce-title {
        font-size: 50.407px;
        max-width: 458px;
        text-align: end;
    }

    .feedBack-reduce-description {
        font-size: 23.721px;
        max-width: 353px;
        text-align: end;
    }
}

@media screen and (max-width: 800px) {
    .feedBack-form-title {
        font-size: 50.407px;
    }

    .feedBack-form-description {
        display: none;
    }

    .feedBack-reduce-title {
        color: #fff;

        text-align: right;
        font-family: Arimo;
        font-size: 50.407px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .feedBack-reduce-description {
        color: #fff;

        text-align: right;
        font-family: Arimo;
        font-size: 23.721px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media screen and (max-width: 700px) {
    .feedBack {
        top: 0;
        padding: 0 24px;
    }

    .feedBack .button-submit,
    .feedBack .formInputs {
        width: 100% !important;
    }

    .feedBack-form-title {
        color: #fff;

        text-align: center;
        text-shadow: 1.744px 1.744px 5.233px rgba(0, 0, 0, 0.55);
        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 700;
        line-height: 98%;
        max-width: 400px;
        margin: 0 auto;
    }

    .feedBack-form-description {
        display: none;
    }

    .feedBack-reduce-title {
        color: #fff;

        text-align: right;
        font-family: Arimo;
        font-size: 29.651px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .feedBack-reduce-description {
        color: #fff;

        text-align: right;
        font-family: Arimo;
        font-size: 13.953px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

@media screen and (max-width: 450px) {
    .feedBack {
        padding-bottom: 0;
    }

    .feedBack-reduce-title {
        font-size: 22.651px;
    }

    .feedBack-reduce-description {
        font-size: 12.651px;
        max-width: 250px;
    }
}

.big-modal-slide-three-list {
    display: flex;
    margin-bottom: 40px;
    border: 4px solid #fff;
    background-color: #000;
    overflow: hidden;
}

.big-modal-slide-three-item {
    width: 100%;
    height: 105%;
    position: relative;
    /* overflow: hidden; */
}

.big-modal-slide-three-img {
    width: 100%;
    height: 100%;
}

.big-modal-slide-three-item-hover,
.big-modal-slide-three-item-active {
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
}

.big-modal-slide-three-item-hover {
    background: linear-gradient(
        180deg,
        rgba(225, 225, 225, 0.18) 0%,
        rgba(255, 255, 255, 0.56) 100%
    );
}

.big-modal-slide-three-item:hover .big-modal-slide-three-item-hover {
    opacity: 1;
}

.big-modal-slide-three-item-active {
    background: linear-gradient(
        0deg,
        rgba(255, 208, 0, 0.5) 0%,
        rgba(255, 208, 0, 0.5) 100%
    );
}

.big-modal-slide-three-item-active-active {
    opacity: 1;
    transition: all 0.5s;
}

.big-modal-slide-three-item-title {
    color: #fff;

    text-shadow: 5px 4px 8.8px rgba(0, 0, 0, 0.91);
    font-family: Arimo;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 1500px) {
    .big-modal-slide-three-item-title {
        font-size: 40px;
    }
}

@media screen and (max-width: 1000px) {
    .big-modal-slide-three-list {
        max-height: 596px;
        flex-wrap: wrap;
        height: 100%;
    }

    .big-modal-slide-three-item {
        width: 50%;
        height: 50%;
    }
}

@media screen and (max-width: 650px) {
    .three.big-modal-slide-title {
        font-size: 25px;
    }

    .big-modal-slide-three-list {
        max-height: 400px;
    }

    .big-modal-slide-three-item {
        max-width: 195px;
        height: 195px;
        width: 50%;
    }

    .big-modal-slide-three-item-title {
        font-size: 34px;
        bottom: 50%;
        transform: translate(-50%, 50%);
    }
}
